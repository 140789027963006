app-logo {
  $logo-width: 150px;

  div.logo {
    display: flex;
    width: $logo-width;
    height: 40px;
    margin: 1rem auto;
    position: relative;
    transition: all 1s;
    opacity: 1;

    &.hidden {
      opacity: 0;
      height: 0;
    }

    img.full-logo {
      width: $logo-width;
      object-fit: contain;
    }
    img.sub-logo {
      position: absolute;
      display: block;
      width: 40px;
      bottom: 0;
      right: 0;
    }
  }
}
