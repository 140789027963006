.card-full-height {
  height: 100%;
}
.card,
.card-body {
  border-radius: 0;
}
.card {
  display: block;
  position: relative;

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .btn {
    height: 4rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    &:hover {
      color: white;
    }
  }
  .btn-premiere,
  .btn-normal-tag {
    height: 1.1rem;
    line-height: 1;
    border-radius: 2px;
    white-space: nowrap;
  }
  .btn-link {
    height: 100%;
    color: $orange;
    &:active {
      color: $orange !important;
    }
  }
  .count {
    font-size: 16px;
  }
}
.card-body {
  %text-attributes {
    color: black;
    overflow-wrap: normal;
    margin-bottom: 30px;
  }
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  padding: 1.25rem;
  h1 {
    font-size: 2rem;
    @extend %text-attributes;
  }
  p {
    font-size: 1rem;
    @extend %text-attributes;
  }
  .btn {
    margin-top: 30px;
    height: 3rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    &:hover {
      color: white;
    }
  }
  .btn-ok,
  .btn-primary {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.card-box-shadow {
  -webkit-box-shadow: 1px 4px 7px 0px rgba(156, 154, 156, 1);
  -moz-box-shadow: 1px 4px 7px 0px rgba(156, 154, 156, 1);
  box-shadow: 1px 4px 7px 0px rgba(156, 154, 156, 1);
}

.message-card {
  border-radius: 0 !important;
}

// if ie10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card {
    display: block !important;
  }
}

// Microsoft Edge Browser 12-18 (All versions before Chromium)
@supports (-ms-ime-align: auto) {
  .card {
    display: block !important;
  }
}

@media (max-width: 766px) {
  .card {
    border-radius: 0;
  }
}
