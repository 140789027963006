$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 4,
  ),
  7: (
    $spacer * 5,
  ),
  8: (
    $spacer * 6.25,
  ),
  9: (
    $spacer * 7.5,
  ),
  10: (
    $spacer * 9.375,
  ),
);

@each $size, $length in $spacers {
  .mr-#{$size} {
    margin-left: initial !important;
    margin-right: #{$length} !important;
  }

  .ml-#{$size} {
    margin-right: initial !important;
    margin-left: #{$length} !important;
  }

  .pr-#{$size} {
    padding-left: initial !important;
    padding-right: #{$length} !important;
  }

  .pl-#{$size} {
    padding-right: initial !important;
    padding-left: #{$length} !important;
  }
}
