/* For use in src/lib/core/theming/_palette.scss */
$md-black: (
  50: #e3e3e3,
  100: #b9b9b9,
  200: #8a8a8a,
  300: #5b5b5b,
  400: #373737,
  500: #141414,
  600: #121212,
  700: #0e0e0e,
  800: #0b0b0b,
  900: #060606,
  A100: #ff4e4e,
  A200: #ff1b1b,
  A400: #e70000,
  A700: #ce0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-red: (
  50: #f0e0e1,
  100: #dab3b5,
  200: #c18084,
  300: #a84d53,
  400: #96262e,
  500: #830009,
  600: #7b0008,
  700: #700006,
  800: #660005,
  900: #530002,
  A100: #ff8585,
  A200: #ff5252,
  A400: #ff1f1f,
  A700: #ff0606,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-gold: (
  50: #fbf7ef,
  100: #f4ecd6,
  200: #eddfbb,
  300: #e6d2a0,
  400: #e0c98b,
  500: #dbbf77,
  600: #d7b96f,
  700: #d2b164,
  800: #cda95a,
  900: #c49b47,
  A100: #ffffff,
  A200: #fff7e8,
  A400: #ffe6b5,
  A700: #ffdd9c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-dark: (
  50: #e3e3e3,
  100: #b9b9b9,
  200: #8b8b8b,
  300: #5d5d5d,
  400: #141414,
  500: #171717,
  600: #141414,
  700: #111111,
  800: #0d0d0d,
  900: #070707,
  A100: #ff5050,
  A200: #ff1d1d,
  A400: #e90000,
  A700: #cf0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-light: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #000000,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #e3e3e3,
    100: #b9b9b9,
    200: #8b8b8b,
    300: #5d5d5d,
    400: #141414,
    500: #171717,
    600: #141414,
    700: #111111,
    800: #0d0d0d,
    900: #070707,
    A100: #ff5050,
    A200: #ff1d1d,
    A400: #e90000,
    A700: #cf0000,
  ),
);

@use '@angular/material' as mat;
@use 'palette-helper';

@import '@angular/material/theming';

@include mat.core();

//light theme
$dinein-light-background: mat.define-palette($md-light, 500, 100, 900);
$dinein-light-card-background: mat.define-palette($md-light, 400, 100, 700);

$dinein-light-primary: mat.define-palette($md-gold, 500, 100, 900);
$dinein-light-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$dinein-light-warn: mat.define-palette(mat.$red-palette);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $dinein-light-primary,
      accent: $dinein-light-accent,
      warn: $dinein-light-warn,
      background: $dinein-light-background,
    ),
  )
);

//dark theme
$dinein-dark-background: mat.define-palette($md-black, 500, 100, 900);
$dinein-dark-card-background: mat.define-palette($md-black, 500, 100, 700);

$dinein-dark-primary: mat.define-palette($md-red, 500, 100, 900);
$dinein-dark-accent: mat.define-palette($md-gold, 500, 50, 900);
$dinein-dark-warn: mat.define-palette(mat.$red-palette);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dinein-dark-primary,
      accent: $dinein-dark-accent,
      warn: $dinein-dark-warn,
      background: $dinein-dark-background,
    ),
  )
);

@include mat.core-theme($light-theme);
@include mat.button-theme($light-theme);
@include mat.all-component-themes($light-theme);

@media (prefers-color-scheme: dark) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.

  @include mat.core-theme($dark-theme);
  @include mat.button-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);
}

.dark {
  @include mat.core-theme($dark-theme);
  @include mat.button-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);
}

$app-active: mat.define-palette($mat-yellow, 800);
