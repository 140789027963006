$app-max-width: 540px;
$heder-height: 64px;
//colors
$white: #ffffff;
$black: #000000;
$light-gray: #d8d8d8;
$gray: #c3c9d1;
$orange: #f4bf02;

$dark: #141414;
$background: $black;

// colors
$green: #009a44;
$voucher-green: #208c4b;
$blue-light: #05c3de;
$blue: #f7fcff;

$red: #830009;
$gold: #dbbf77;

$violet: #772583;
$dark_sea_green: #0f833d;
$sapphire: #2640aa;
$dark_blue_grey: #0f1b2f;
$pale_grey: #f4f4f7;
$black_54: #8a0000;
$dark_indigo: #0b1e52;
$pinkish_red: #ed1c24;
$sapphire_tag: #2440b6;
$gray-500: #97999b;
$gray-600: #d6d6d6;

$color-title: $gold;
$color-subtitle: #b5c5dc;
$color-content: #b4c4dc;
$color-group-label: $gold;

$color-primary: #141414;
$color-secondary: $red;
$color-tertiary: $gold;
$color-black: #000000;
$color-warn: #f94646;

$primary: $color-secondary;

$btn-next-background-color: $pinkish_red;
$btn-gray-background-color: $gray-500;

$font-size-base: 0.9rem;
$font-family-base: 'Neuzeit Grotesk';
$font-family-regular: 'Neuzeit Grotesk';
$font-family-heading: 'Neuzeit Grotesk';

$primary-color: $color-secondary;
$default-color: #bdbcbb;
$default-color-text: #4a4a4a;

$default-placeholder-color: #9c9da0;

$background-header: #4c5bde;
$background-color: #edeeef;

$background-subheader: #1e1d1e;
$secondary-color: #8090de;

$seats-columns: 50;
$seats-column-size: 21;
$seats-column-margin-sides: 2;
$seats-column-margin-bottom: 4;
$seats-row-size: 20;
$seat-font-size: 12px;

$background-icon-free: transparent;
$background-icon-mine: transparent;
$background-icon-occupied: transparent;

$table-border-color: #3e3e3e;
$container-max-width: 1100;
// bootstrap -> card
$card-bg: transparent;
$card-border-width: 0;
//$card-background: $dinein-dark-background;
$card-text: #ffffff;

$agreement-card-background-color: #e8e8eb;
$agreement-card-checkbox-border-color: #c6c6c6;
$agreement-card-checkbox-active-backgorund-color: #0a71e9;

$asterisk-valid-color: #000000;
$asterisk-invalid-color: #ff0000;

$expanded-height: 100;

//#141414 - card background
//#000954 - manifest background
//#0a71e9 - buttons
