app-order-page {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  color: $white;

  .container {
    margin-top: 0 !important;

    .product-list-action {
      text-align: right;
    }
  }

  .box {
    line-height: 17px;
    text-align: left;

    &-left {
      flex: 1 1 0;
      h2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
      }
      p {
        color: #7a7f86;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0em;
      }
    }
    &-right {
      text-align: right;
      .counter {
        color: #c3c9d1;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        margin-top: 0;
        margin-bottom: 0.5rem;
      }
      .price {
        font-size: 16px;
        line-height: 19px;
        &-value {
          font-weight: 500;
        }
      }
    }
  }

  .btn-box {
    height: 50px;
    display: block;
    margin: -18px 0 0 16px;
    button {
      height: 50px;
    }
  }

  .summary {
    width: 200px;
    margin: 1rem auto;
    text-align: center;

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
  }

  mat-form-field {
    width: 100%;
  }

  .card-article {
    opacity: 1;
    transition: all 0.6s;

    &.hidden {
      opacity: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }
  }

  .tip-slider {
    .thumb {
      height: 48px;
      padding: 8px;
      margin: auto 0;
      line-height: 32px;
    }
  }
}
