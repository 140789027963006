app-payment-provider-payu-component,
app-payment-provider-conotoxia-component {
  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid lightgray;
  }

  .pay-method-container {
    padding: 0 10px 0 10px;

    .pay-method-panel {
      border: solid 1px #ffffff4d;
      padding: 1rem;
      margin: 0 0 5px 0;
      border-radius: 5px;

      .panel-head {
        top: 10px;
        height: 25px;
      }

      .label {
        font-weight: 600;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin: 0;
      }

      .image {
        position: relative;
        display: inline-flex;

        img {
          position: relative;
          height: 25px;
          width: auto;
        }
      }
    }

    .card-container {
      display: flex;
      .card {
        width: 400px;
        height: 200px;
        background-color: white;
        border: 1px solid black;
        img {
          width: 80px;
        }
      }

      .card-selected {
        border: 1px solid lightgray !important;
      }
    }

    .pay-by-link-container {
      display: block;
      .pay-by-link {
        display: inline-block;
        text-align: center;
        padding: 0.5rem;
        border: 1px solid transparent;
        cursor: pointer;
        background: white;
        border-radius: 5px;
        img {
          height: 25px;
          width: auto;
        }
      }

      .selected {
        border: 1px solid lightgray !important;
        box-shadow: 0 0 3px gray, 0 0 5px lightgray;
      }
    }
  }
}
