app-products-page {
  .mat-toolbar {
    //height:50px;
  }
  .container {
    .group-articles {
      .card-article {
        background-color: $background !important;
      }
    }
  }
}
