@import 'theme';

// Font Awesome
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

@import 'fonts';
@import 'variables';
@import 'mixed';

* {
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: mat-color($dinein-light-background);
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
a {
  color: mat-color($dinein-light-accent);
}

button:focus {
  outline: none !important;
}

input {
  background-color: mat-color($dinein-light-background);
  color: $white !important;
  &:disabled {
    color: $gray !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px mat-color($dinein-light-background) inset !important;
  background-color: mat-color($dinein-light-background) !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}

.landscape {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 2rem;
  color: #ffffff;
  text-align: center;
}

.watermark {
  background: url('/assets/images/D.svg') center center;
  background-repeat: no-repeat;
  min-height: 600px;
}

.container {
  overflow-y: auto;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  max-width: 540px !important;
}

.cdk-overlay-container {
  z-index: 9999;
}

.app-container {
  position: absolute;
  max-width: $app-max-width;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  //overflow-x: hidden;
  min-height: 100%;
  height: 100%;
  left: 0;
  right: 0;
  overflow-y: auto;

  .header,
  .footer {
    width: 100%;
    max-width: $app-max-width;
    margin: 0px;
    background-color: mat-color($dinein-light-background);
  }

  .header-fixed {
    z-index: 200;
    transition: 0.3s ease-in;

    + .container {
      transition: 0.3s ease-in;
      top: 220px;
      margin-bottom: 64px;
    }

    &.rollup + .container {
      top: 64px;
    }

    .navbar {
      height: 90px;
      transition: 0.3s ease-in;
      opacity: 1;

      &-brand {
        margin: 0 1rem;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    &.rollup,
    &.with-query {
      height: 67px;
      + .container {
        margin-top: 67px;
        padding-bottom: 5px;
      }
      .navbar {
        margin-top: -85px;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    color: #ffffff40;
    text-align: center;

    font-size: 10px;
    line-height: 17.6px;

    &-extra {
      width: 100%;
      padding: 0.75rem;
      margin-right: auto;
      margin-left: auto;
      z-index: 100;
      height: 68px;

      button {
        width: 100%;
      }
    }
  }
}

app-order-page {
  .app-container .header-fixed.rollup + .container {
    margin-top: 0px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.active {
  color: mat-color($app-active);
}
.valid,
.primary {
  color: mat-color($dinein-dark-primary);
}

.modal-content {
  background-color: $color-primary !important;
  border-radius: 8px !important;

  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  .card {
    box-shadow: none !important;
    border: none !important;
  }
}

.mat-toolbar {
  background-color: $background; //mat-color($dinein-dark-background);
  color: $white;
  padding: 0 0.75rem !important;

  .nav-button {
    margin-right: 1rem;

    mat-icon {
      font-size: 46px;
      width: 46px;
      height: 46px;
      line-height: 46px;
    }
  }

  .mat-form-field-flex {
    align-items: center;
  }

  .mat-form-field-prefix {
    margin-top: 10px;
  }

  .mat-form-field-label:not(.mat-form-field-empty),
  .mat-focused .mat-form-field-label {
    color: $gray !important;
  }
}

h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 119%;
}

.group-label {
  color: $color-group-label;
  text-transform: uppercase;
}

.mat-card {
  margin-bottom: 6px;
  background-color: mat-color($dinein-dark-background);
  border-radius: 8px !important;

  mat-card-title-group {
    min-height: 80px;
  }

  &-header {
    justify-content: center;
    font-size: 21px;
    line-height: 119%;

    &-text {
      width: 100%;
      margin: 0 !important;
      text-align: center;
    }
  }

  &-title {
    align-items: center;
    color: $color-title;
  }
  &-subtitle {
    font-size: 12px;
    line-height: 130%;
    color: $color-subtitle;
  }
  &-content {
    font-size: 14px;
    line-height: 130%;
    color: $color-content;
  }

  &.card-group {
    padding: 0 16px;
    color: $background !important;
    background: mat-color($dinein-dark-primary) !important;
    .mat-card-title-group {
      min-height: 40px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
      .mat-card-title {
        margin-bottom: 0;

        font-weight: normal;
        font-size: 16px;
        line-height: 119%;

        color: $background !important;
        font-weight: 600;
      }
    }
  }

  &.card-details {
    img {
      width: 120px;
      aspect-ratio: 1/1;
      object-fit: cover;
      margin: 0;
      border-radius: 10px;
    }
    button {
      &.close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        background-color: $red;
        color: $white;
        text-shadow: none;
        opacity: 0.8;
      }
      &.special-btn {
        width: 100%;
        color: #b6c2d2;
        background: #2e3744;
        margin-bottom: 1rem;
      }
    }

    h5.group-label + p {
      font-weight: normal;
      font-size: 14px;
      line-height: 132%;
    }

    .title {
      align-items: center;
      margin-top: 12px;
      &-price {
        text-align: right;
      }
    }
  }

  .product-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;

    //align-items: center;
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
    color: $white;
    font-weight: 400;
    min-height: 64px;

    &.product-list-detail,
    .product-list-detail {
      flex: 1 1 0;
      padding-right: 1rem;
      .product-name {
        font-size: 16px;
        line-height: 19px;
        &-small {
          font-size: 14px;
          margin-top: 2px;
        }
      }
      .product-description {
        color: $gray;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .product-list-action {
      display: flex;
      flex-direction: column;
      .product-price {
        align-self: flex-end;
      }
      button {
        width: 29px;
        height: 29px;
        padding: 0;
        min-width: 29px;
        min-height: 29px;
        line-height: 29px;
        font-size: x-large;
        align-self: flex-end;
        margin-top: 0.5rem;
      }
      .product-in-cert {
        align-self: flex-end;
        color: $orange;
      }
      mat-icon {
        align-self: end;
      }
    }
  }

  .product-list-subitem {
    border-radius: 0;
    &:first-of-type {
      border-top: 1px solid #ffffff1f;
    }
  }
}

.submit-btn {
  button {
    width: 100%;
    height: 50px;
    margin-top: 4px;
  }
}

.action-button {
  width: 100%;
  padding: 0.5rem !important;

  font-size: calc(min(max(1vw, 13px), 16px));
  font-weight: 400;
}
.app-container .footer-extra {
  padding: 0.75rem 0 0.25rem 0;
  margin-bottom: 1rem;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.rcb {
  margin: 5px;
}

.text-right {
  text-align: right;
}

.mat-checkbox {
  display: flex !important;
  .mat-checkbox-layout {
    width: 100%;
    .mat-checkbox-label {
      width: 100%;
    }
  }
}

.mat-radio-button {
  display: flex !important;
  .mat-radio-label {
    width: 100%;
    .mat-radio-label-content {
      width: 100%;
    }
  }
}

.mat-checkbox-inner-container {
  margin-right: 16px !important;
}
.mat-radio-label-content {
  padding-left: 16px !important;
}

.glabel {
  display: inline-block;
  margin: 0;
  &:after {
    display: block;
    content: '';
    border-bottom: solid 2px red;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &.has-error:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }
  .error {
    color: red;
  }
}

.success-snackbar {
  background: #00af85 !important;
  color: #ffffff !important;
}
.error-snackbar {
  background: #ff0000 !important;
  color: #ffffff !important;
}
.info-snackbar {
  background: #505c6d !important;
  color: #ffffff !important;
}

.call-service-modal {
  color: white;
  .modal-header {
    border-bottom: 1px solid #e9ecef20;
  }
}

.tip {
  min-width: 56px !important;
  &-custom {
    max-width: 64px !important;
  }
}

.discount-price-box {
  .before {
    text-decoration-line: line-through;
  }
  .after {
    color: #198754;
  }
}

.text-green {
  color: #198754;
}

mat-dialog-container {
  background-color: #171717 !important;
  //padding: 0px 0px 0px 0px !important;
  overflow: hidden !important;
  border: 1px solid #0a71e9;
}

//components
@import 'core/components/article-card';

@import 'core/components/payment/credit-card/credit-card';
@import 'core/components/payment/proxy/provider/payu/payu-payment-provider.component';
@import 'core/components/payment/payment-method/payment-method';
@import 'core/components/logo/logo.component.scss';

// pages
@import 'core/pages/scanner';
@import 'core/pages/products';
@import 'core/pages/order';

@import '_bootstrap/card';

.scalable-font {
  font-size: calc(min(max(1vw, 13px), 16px)) !important;
}

.divider {
  height: 5rem;
}

.panel-head {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  > label {
    display: flex;
    flex-direction: row;
    margin-bottom: 0 !important;
    &:hover {
      cursor: pointer;
    }
  }
  .label {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .cr {
    position: relative;
    display: inline-block;
    border: 1px solid $agreement-card-checkbox-border-color;
    border-radius: 2px;
    width: 1.7em;
    height: 1.7em;
    margin-right: 1em;
    color: $blue-light;
    min-width: 1.7em;

    .cr-icon {
      position: absolute;
      line-height: 0;
      top: 12px;
    }
  }
  .label-content {
    // font-size: 0.6rem;
  }
  .label-content-big {
    font-size: 0.8rem;
    margin-top: 2px;
  }
  label input[type='checkbox'] {
    display: none;
  }
  label input[type='checkbox'] + .cr > .cr-icon {
    opacity: 0;
  }
  label input[type='checkbox']:checked + .cr > .cr-icon {
    opacity: 1;
  }
  label input[type='checkbox']:disabled + .cr {
    opacity: 0.5;
  }
}

.checkbox-active {
  border-radius: 3px !important;
  border-color: $agreement-card-checkbox-active-backgorund-color !important;
  color: white !important;
  background-color: $agreement-card-checkbox-active-backgorund-color;

  i {
    font-size: 0.8rem;
    left: 5px !important;
  }
}

.radio-container {
  position: relative;
  margin: 0px;
  padding: 0 20px 0 35px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid $gray-500;
}

.radio-container:hover input ~ .checkmark.noActive {
  background-color: #ccc;
}

.radio-container .checkmark.active {
  background-color: white;
}

.checkmark.active:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-container .checkmark.active:after {
  display: block;
}

.radio-container .checkmark.active:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $sapphire;
}

//$modal-fade-transform: scale(.8);
//.modal.fade
.modal-dialog {
  position: relative;
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    top: 5000px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.mat-app-background {
  background: mat-color($dinein-light-background);
}

.mat-card {
  background: mat-color($dinein-light-card-background) !important;
}

.mat-toolbar {
  background: mat-color($dinein-light-background) !important;
}

app-products-page {
  .mat-card {
    background: mat-color($dinein-light-card-background) !important;
    color: $card-text !important;
    .product-list-item {
      color: $card-text !important;
    }
  }
}

mat-accordion {
  mat-expansion-panel {
    background: transparent !important;
    box-shadow: none !important;

    mat-expansion-panel-header {
      //padding: 0 !important;
      border-bottom: 2px solid rgba(255, 255, 255, 0.1215686275);

      .mat-expansion-panel-header-title {
        margin-right: 0 !important;
      }
    }
    .mat-expansion-panel-header {
      padding: 0 12px;
    }
    .mat-expansion-panel-content {
      //  margin-top: 0.5rem;
    }

    .mat-expansion-panel-body {
      padding: 0 !important;
    }

    .group-bar {
      color: $color-group-label;
      text-transform: uppercase;

      $group-size: 50px;
      &-label {
        font-weight: 600;
        font-size: 20px;
        margin: auto 0;
        overflow: hidden;
      }
      &-img {
        height: $group-size;
        img {
          width: $group-size;
          height: $group-size;
          border-radius: 8px;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
    }
  }
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: transparent !important;
}

.mat-checkbox-layout,
.mat-radio-label-content {
  white-space: normal;
}

.welcome {
  a:link,
  a:visited {
    color: $orange;
  }
}

.payment-btns {
  button {
    width: 100%;
    margin-bottom: 0.3rem;
  }
}

.summary {
  height: calc(100% - 84px);

  &-logo {
  }

  &-content {
    height: 100%;
    overflow-y: auto;
  }
}

.header {
  .title {
    font-size: 16px;
    line-height: 1.1;
  }
}

.counter {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: #c3c9d1;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

body.dark {
  background-color: mat-color($dinein-dark-background);

  a {
    color: mat-color($dinein-dark-accent);
  }

  input {
    //background-color: mat-color($dinein-dark-background);
    color: $white !important;
    &:disabled {
      color: $gray !important;
    }
  }
  input[readonly] {
    color: $gray !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px mat-color($dinein-dark-background) inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;
  }

  .header,
  .footer {
    background-color: mat-color($dinein-dark-background);
  }

  .mat-app-background {
    background: mat-color($dinein-dark-background);
  }

  .mat-card {
    background: mat-color($dinein-dark-card-background) !important;
    border: 1px solid $gold;
  }

  .mat-toolbar {
    background: mat-color($dinein-dark-background) !important;
  }

  .membership-card {
    width: 100%;

    background: #000954 !important;
    border-radius: 5px;

    .membership-card-header-image {
      background-image: url('/assets/images/user.svg');
      background-size: cover;
    }
  }

  app-products-page {
    .mat-card {
      background: mat-color($dinein-dark-card-background) !important;
      color: $card-text !important;
      .product-list-item {
        color: $card-text !important;
      }
    }
  }

  mat-accordion {
    mat-expansion-panel {
      background: transparent !important;

      mat-expansion-panel-header {
        //padding: 0 !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.1215686275);
      }

      .group-bar {
        color: $color-group-label;
      }
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background: transparent !important;
  }

  .welcome {
    a:link,
    a:visited {
      color: $orange;
    }
  }

  label span.required {
    color: mat-color($dinein-dark-accent);
  }

  snack-bar-container.mat-snack-bar-container.success-snackbar {
    background: $blue !important;
  }

  .product-name {
    color: $gold !important;
  }
}

app-order-page {
  .header-fixed + .container {
    top: 160px !important;
  }
}

.mat-toolbar.details {
  height: 96px !important;
}
